import * as React from 'react';

import { Card } from '../components/Card';
import { Layout } from '../components/Layout';

const API_URL = 'https://api.offscriptworship.com/v1';

class IndexPage extends React.Component {
  state = {
    name: '',
    email: '',
    phone: '',
    message: '',

    loading: true,
    events: [],
    videos: null,
    error: null,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    try {
      const events = await fetch(`${API_URL}/events`, {
        method: 'GET',
      });
      const videos = await fetch(`${API_URL}/videos`, {
        method: 'GET',
      });

      const eventData = await events.json();
      const videoData = await videos.json();

      console.log(eventData);

      this.setState({
        loading: false,
        events: eventData.data,
        videos: videoData.data,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: error.response });
    }
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { name, email, phone, message, loading, events, videos } = this.state;
    return (
      <Layout>
        <div>
          <div className="banner">
            <h1>Off Script Worship.</h1>
          </div>

          {/* About */}
          <section id="about">
            <h2>About</h2>
            <div style={{ marginTop: '5%', marginBottom: '5%' }}>
              <p>
                We are a group of worshippers who are all about community. It is
                our desire to see God more in our community, to see His love
                represented in acceptance and inclusion. We invite people from
                all different walks of life to join us in celebrating God's
                creation and our devotion to a great father that we all share.
                We believe that God inhabits the praises of His people. And in
                those times of corporate worship, we are connected with God and
                the functioning body of Christ. The functioning body of Christ
                provides healing in mind, body, and soul. We rely on the
                simplicity of the gospel and just enjoy spending time with our
                brothers and sisters with no other agenda. We would love to see
                you at the next Offscript.
              </p>
            </div>
          </section>
          {/* End About */}

          {/* Videos */}
          <section id="videos" className="section">
            <h2>Videos</h2>
            <div className="scroller">
              {loading ? (
                <p>Loading</p>
              ) : (
                videos.map((video, i) => (
                  <Card key={i} item={video} type="video" />
                ))
              )}
            </div>
          </section>
          {/* End Videos */}

          <section id="contact" className="contact">
            <h2>Contact</h2>
            <div className="contactFormWrapper">
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                  />
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleChange}
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={this.handleChange}
                  />
                  <textarea
                    type="text"
                    name="message"
                    placeholder="Message"
                    value={message}
                    onChange={this.handleChange}
                  />
                  <button type="submit">Send</button>
                </div>
              </form>
              <div className="contact-image" />
            </div>
            <p>
              or email at:{' '}
              <a href="mailto:contact@offscriptworship.com">
                contact@offscriptworship.com
              </a>
            </p>
          </section>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
