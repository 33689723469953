import * as React from 'react';

import './card.scss';

export const Card = ({ item }) => {
  return (
    <div className="video-card">
      <iframe
        src={item.videoUri}
        frameBorder={0}
        allowFullScreen
        style={{ height: 300, width: 500 }}
        title={item.title}
      />
    </div>
  );
};
